import React from 'react';
import { TwitterPicker } from 'react-color';
import Highlight from 'react-highlight.js';

require('./App.css');
require('./Highlight.css');

class App extends React.Component {
  state = {
    color: '#243a72',
    shade: '#1b2b56',
    tint: {
      red: 91,
      green: 107,
      blue: 149
    }
  };

  changeColor = color => {
    var Harmonizer = require('color-harmony').Harmonizer;
    var harmonizer = new Harmonizer();
    var hex2RGB = require('hex-rgb');
    if (color.hex.toLowerCase() === '#ffffff') {
      this.setState({
        color: color.hex,
        shade: harmonizer.shades(color.hex, 8)[2],
        tint: hex2RGB(harmonizer.shades(color.hex, 8)[2])
      });
    } else if (color.hex.toLowerCase() === '#000000') {
      this.setState({
        color: color.hex,
        shade: harmonizer.tints(color.hex, 8)[2],
        tint: hex2RGB(harmonizer.tints(color.hex, 8)[2])
      });
    } else {
      this.setState({
        color: color.hex,
        shade: harmonizer.shades(color.hex, 8)[2],
        tint: hex2RGB(harmonizer.tints(color.hex, 8)[2])
      });
    }
  };

  createTheme() {
    var css = `
/* Login logo */
.login-entry .opsview-logo {
  background-image: url('../images/logo-login.png') !important;
}

/* Login button */
div.login-box button {
  background: ${this.state.color};
}

/* Login background color */
body {
  background: ${this.state.color} !important;
}

/* Navigation color */
div.Navigation, div.Navigation-subCategories, div.u-backgroundColor--brand, div.u-backgroundColor--primary-tint {
  background: ${this.state.color} !important;
}

/* Search Field Color */
.Search--input {
  background: ${this.state.color} !important;
  border: 1px solid #fff !important;
}

/* Bottom Bar */
.ov-panel-purple .x-panel-header {
  background: ${this.state.color}!important;
}

/* Active Navbar Item */
.Navigation-categoriesItem.is-active {
  background-color: rgba(${this.state.tint.red}, ${this.state.tint.green}, ${
      this.state.tint.blue
      }, 0.4) !important;
}

/* User Dropdown */
.Dropdown-item--link {
  background-color: ${this.state.color} !important;
}

/* Active User Dropdown */
.Dropdown-item--link:hover {
  background-color: ${this.state.shade} !important;
}
    `;

    return css;
  }

  createCodeBlock() {
    return (
      <div className="column">
        <Highlight language="css">{this.createTheme()}</Highlight>
      </div>
    );
  }

  initiateDownload = () => {
    var element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(this.createTheme())
    );
    element.setAttribute('download', 'custom.css');
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  render() {
    return (
      <div className="ui container">
        <div className="ui column grid">
          <div className="twelve wide column">
            <TwitterPicker
              color={this.state.color}
              onChangeComplete={this.changeColor}
              triangle="hide"
            />
          </div>
          <div className="four wide column right aligned">
            <button className="ui basic button" onClick={this.initiateDownload}>
              <i className="icon download" />
              Download
            </button>
          </div>
        </div>
        <div className="ui one column grid">{this.createCodeBlock()}</div>
      </div>
    );
  }
}

export default App;
